<template>
<div class="mt-10 mr-12 ml-12" >
  <h1 style="font-size:32px; font-weight: 500; text-align:center" v-if="$vuetify.breakpoint.smAndDown" >Update Account</h1>
  <v-row>
      <v-col cols="12" sm='3'>
        <h1 v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</h1>
          <v-card rounded="xl" class="mt-10">
            <v-img v-if="attachment == null" src="@/assets/user.png" contain ></v-img>
            <v-img v-else :src="url"  style="background-size: cover" width="100%" height="100%" ></v-img>
          </v-card>
          <v-layout align-center justify-center class="mt-5" column >
          <input id="fileUpload" value="attachment" type="file"  hidden @change="getSelectedFile">
          <v-btn class="primary" small style="width:80%" @click="chooseFiles()" >Update Picture</v-btn>
          <v-btn text small style="width:80%" class="mt-3" @click="removeImage">Use Default Image</v-btn>

          </v-layout>
        
        
      </v-col>
      <v-col cols="12" sm="8">
        <h1 style="font-size:32px; font-weight: 500;" v-if='$vuetify.breakpoint.mdAndUp'>Update Account</h1>
         <p style="font-size: 14px; color: grey" class=" mt-5">
          Account Details
        </p>
        <v-form :style="$vuetify.breakpoint.mdAndUp?'width:80%':''" >
          <v-text-field label="Name" outlined v-model="name"  dense></v-text-field>
          <v-text-field label="Username" outlined v-model="username"  dense></v-text-field>
          <v-text-field label="Email" outlined v-model="email"  dense readonly></v-text-field>
        </v-form>
        <p style="font-size: 14px; color: grey" class=" mt-5">
          Update Password
        </p>
        <v-form :style="$vuetify.breakpoint.mdAndUp?'width:80%':''" >
          <v-text-field label="Old Password" outlined v-model="oldPassword"  dense></v-text-field>
          <v-text-field label="New Password" outlined v-model="oldPassword"  dense></v-text-field>


        </v-form>

        <v-layout justify-space-between :style="$vuetify.breakpoint.mdAndUp?'width:80%':''"  class="mt-5">
          <v-btn text color="cancel">Cancel</v-btn>
          <v-btn class="primary">Save</v-btn>
        </v-layout>

      </v-col>
      </v-row>
</div>
</template>

<script>
export default {
  data: () => ({
    name: null,
    username: null,
    email: null,
    oldPassword: null,
    url: null,
    attachment: null
  }),

  created: function() {
   
      this.name = this.userInfo.person_name
      this.username = this.userInfo.username
      this.email = this.userInfo.email
      this.attachment = this.userInfo.image
      if(this.attachment != null){
      this.url = URL.createObjectURL(this.attachment)}
    
  
   

  },

  methods: {
    chooseFiles() {
        document.getElementById("fileUpload").click()
    },

    getSelectedFile(e){
     
      this.attachment = e.target.files[0]
      this.url = URL.createObjectURL(this.attachment)
      console.log(this.attachment)
    },

    removeImage: function(){
      document.getElementById("fileUpload").value = null; this.url=null; this.attachment = null;
    }
    
    
    },

  computed: {
     userInfo: function(){
      return this.$store.state.auth.userInfo;
    },
  }


}
</script>

<style>

</style>